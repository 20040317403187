<template>
  <div class="min-w-100 min-vh-100 overflow-hidden">
    <v-row class="display-relative w-100 m-0 p-0">
      <v-toolbar dense text elevation="0" max-height="48" class="p-0">
        <Tabs :activeTab="activeTab" :tabs="tabs" :resetActiveCategory="resetActiveCategory" />
        <!-- <v-tabs v-model="activeTab" color="#e3ad1a" align-with-title next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left" show-arrows grow optional>
          <v-tabs-slider color="#000000" />
          <v-tab v-for="(tab, i) in tabs" :key="i" @click="resetActiveCategory(i)" data-bs-toggle="tooltip"
            data-bs-placement="bottom" title=" " :disabled="tab.disabled">
            {{ tab.title }}
          </v-tab> 
        </v-tabs>-->
      </v-toolbar>
    </v-row>

    <!-- Categories for the selected Tab / Menu Small ViewPorts -->
    <v-row v-if="$vuetify.breakpoint.smAndDown ? true : false">
      <Categories :tabs="tabs" :activeTab="activeTab" :activeCategory="activeCategory"
        :getCategoryItemsForSelectedTab="getCategoryItemsForSelectedTab"
        :getSelectedCategoryName="getSelectedCategoryName" :selectCategoryAndClose="selectCategoryAndClose"
        :selectCategory="selectCategory" />
      <!-- <div v-if="$vuetify.breakpoint.smAndDown ? true : false" class="mt-5 pr-5 pl-5">
        <v-select :items="getCategoryItemsForSelectedTab" v-model="activeCategory" item-value="name" item-text="name"
          :label="getSelectedCategoryName" solo dense active-class="black white--text" dark
          :menu-props="{ offsetY: true }" class="ml-4 mr-4" menu-prop:closeOnContentClick>
          <template v-slot:item="{ item }">
            <v-list-item @click="selectCategoryAndClose(item)">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </div> -->

    </v-row>
    <!-- Categories for the selected Tab / Menu Medium to Large ViewPorts -->
    <v-row dense v-if="activeTab !== null">
      <v-col md="4" class="ml-3 p-4" v-if="$vuetify.breakpoint.smAndDown ? false : true">
        <Categories :tabs="tabs" :activeTab="activeTab" :activeCategory="activeCategory"
          :getCategoryItemsForSelectedTab="getCategoryItemsForSelectedTab"
          :getSelectedCategoryName="getSelectedCategoryName" :selectCategoryAndClose="selectCategoryAndClose"
          :selectCategory="selectCategory" />
        <!-- <v-card>
          <v-tabs-items v-model="activeTab">
            <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="i">
              <v-list nav dense>
                <h6 class="text-decoration-underline mb-2">
                  {{ tab.title }}
                </h6>
                <v-list-item-group v-model="activeCategory" active-class="black white--text" color="#000000">
                  <v-list-item v-for="(category, catIndex) in tab.TabCategories" :key="catIndex"
                    @click="selectCategory(catIndex)" data-bs-toggle="tooltip" data-bs-html="true" title={category.tip}>
                    <v-list-item-icon>
                      <v-icon v-text="category.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="category.name" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-tab-item>
          </v-tabs-items>
        </v-card> -->
      </v-col>

      <!-- DropBox Component-->
      <v-col :md="$vuetify.breakpoint.smAndDown ? 11 : 7" v-if="getSelectedCategoryName === 'Bulk Load(EBM/Etax)'"
        :class="$vuetify.breakpoint.smAndDown ? 'ml-10' : 'ml-5 mr-5 p-0'">
        <v-container tag="div" class="p-0 d-sm-flex flex-column justify-end">
          <v-row>
            <!-- UPLOAD CONTAINER -->
            <v-col cols="12" md="12" sm="12">
              <Uploader :categoryName="formattedCategoryName" :tabName="formattedTabName" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <!-- FETCH API DATA -->
      <v-col :md="$vuetify.breakpoint.smAndDown ? 11 : 7"
        :class="$vuetify.breakpoint.smAndDown ? 'ml-10 pt-8' : 'ml-5 mr-5 pt-8'" v-else>
        <div class="d-flex flex-row flex-wrap justify-content-between gap-3 ">
          <TinSelector />
          <DateRangePicker />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Import the Uploader
import Uploader from "@/components/Upload/Uploader.vue";
import TinSelector from "@/components/MainBody/Home/Scaffold/TinSelector.vue";
import DateRangePicker from "@/components/MainBody/Home/Scaffold/DateRangePicker.vue";
import Tabs from "@/components/MainBody/Home/Scaffold/Tabs.vue";
import Categories from "@/components/MainBody/Home/Scaffold/Categories.vue";
import { tabs } from "@/components/MainBody/Home/Scaffold/tabsData.js";


export default {
  components: {
    Tabs,
    Categories,
    Uploader,
    TinSelector,
    DateRangePicker,
  },

  data: () => ({
    selectedRange: null,
    closeOnContentClick: true,
    selectedSubCat: 0,
    activeTab: 0,
    activeCategory: 0,
    tabs: tabs,
  }),
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
  methods: {
    handleDateRangeUpdate(range) {
      this.selectedRange = range;
    },
    initializeTooltips() {
      const tooltipElements = document.querySelectorAll('[data-toggle="tooltip"]');
      tooltipElements.forEach(element => {
        element.addEventListener('mouseenter', this.showTooltip);
        element.addEventListener('mouseleave', this.hideTooltip);
      });
    },
    disposeTooltips() {
      const tooltipElements = document.querySelectorAll('[data-toggle="tooltip"]');
      tooltipElements.forEach(element => {
        element.removeEventListener('mouseenter', this.showTooltip);
        element.removeEventListener('mouseleave', this.hideTooltip);
      });
    },
    showTooltip(event) {
      const tooltip = event.currentTarget.getAttribute('title');
      event.currentTarget.removeAttribute('title');
      event.currentTarget.setAttribute('data-tooltip', tooltip);
    },
    hideTooltip(event) {
      const tooltip = event.currentTarget.getAttribute('data-tooltip');
      event.currentTarget.removeAttribute('data-tooltip');
      event.currentTarget.setAttribute('title', tooltip);
    },
    selectCategory(index) {
      this.activeCategory = index;
    },
    resetActiveCategory(index) {
      this.activeTab = index;
      this.activeCategory = 0; // Reset the activeCategoryIndex to 0
    },
    selectCategoryAndClose(item) {
      this.activeCategory = item.name; // Set the selected category
      this.$refs.selectMenu.isActive = false; // Close the v-select dropdown
    },

    // Cleaning category names for API routes
    cleanString(input) {
      // Remove anything after the opening parenthesis
      let cleanedString = input.replace(/\(.*/, '');

      // Replace special characters within words with a single hyphen
      cleanedString = cleanedString.replace(/[^\w\s-]/g, '');

      // Replace consecutive spaces with a single hyphen
      cleanedString = cleanedString.replace(/\s+/g, '-');

      // Remove leading and trailing hyphens
      cleanedString = cleanedString.replace(/^-+|-+$/g, '');

      return cleanedString.toLowerCase(); // Convert to lowercase
    },
  },
  computed: {
    getCategoryItemsForSelectedTab() {
      const selectedTab = this.tabs[this.activeTab];
      const items = [];

      if (selectedTab) {
        selectedTab.TabCategories.forEach(category => {
          items.push({
            name: category.name,
            icon: category.icon
          });
        });
      }
      return items;
    },
    formattedTabName() {
      const tabTitle = this.tabs[this.activeTab].title;
      let formattedTitle;
      switch (tabTitle) {
        case 'Data Analytics':
          formattedTitle = 'dana';
          break;
        case 'Tax Computation':
          formattedTitle = 'taco';
          break;
        case 'Annexure Filling':
          formattedTitle = 'annefi';
          break;
        default:
          formattedTitle = 'basta';
      }
      return formattedTitle;
    },
    formattedCategoryName() {
      if (this.activeCategory !== null) {
        const selectedCategory = this.tabs[this.activeTab].TabCategories[this.activeCategory];
        return this.cleanString(selectedCategory.name);
      }
      return ''; // Return an empty string if no category is selected
    },
    getSelectedCategoryName() {
      const selectedCategory = this.tabs[this.activeTab].TabCategories[this.activeCategory];
      return selectedCategory.name;
    },
    getSelectedCategoryTip() {

      const selectedCategory = this.tabs[this.activeTab].TabCategories[this.activeCategory];
      return selectedCategory.tip;
    },
    categoryNameFormatter(subCategory) {
      if (subCategory != null) {
        return subCategory.toLower();
      }
      return '';
    }
  },
  mounted() {
    // Initialize Bootstrap tooltips when the component is mounted
    this.initializeTooltips();
  },
  beforeDestroy() {
    // Clean up tooltips when the component is destroyed
    this.disposeTooltips();
  },
}
</script>

<style lang="scss" scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

/* width */
::-webkit-scrollbar {
  width: 0.5em;
  scrollbar-width: none;
}


/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-track:hover {
  background: #181717;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #616161;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom right, #ffe000, #799f0c);
}

@supports (scrollbar-color: linear-gradient(to bottom right, #ffe000, #799f0c) #181717) {
  * {
    scrollbar-color: linear-gradient(to bottom right, #ffe000, #799f0c) #181717;
    scrollbar-width: thin;
  }

  .v-tabs .v-icon {
    font-size: 24px;
    /* Adjust the size as needed */
  }

  .tooltip {
    position: absolute;
    z-index: 1020;
    display: block;
    visibility: visible;
    padding: 5px;
    font-size: 13px;
    opacity: 0;
    filter: alpha(opacity=0)
  }

  .tooltip.in {
    opacity: .8;
    filter: alpha(opacity=80)
  }

  .tooltip-inner {
    width: 350px;
    height: 30px;
    padding: 3px 8px;
    color: #314A5B;
    text-align: center;
    font-weight: 900;
    background: -webkit-gradient(linear, left top, left 25, from(#FFFFFF), color-stop(4%, #A2BBCC), to(#FFFFFF));
    background: -moz-linear-gradient(top, #FFFFFF, #A2BBCC 1px, #FFFFFF 25px);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 15px;
    border: 1px solid #314A5B;
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0
  }
}
