<template>
  <!-- /form card reset password -->
  <div class="col-md-6 offset-md-3">
    <span class="anchor" id="formResetPassword"></span>
    <hr class="mb-5">

    <!-- form card reset password -->
    <div class="card card-outline-secondary">
      <div class="card-header">
        <h3 class="mb-0 font-normal">Password Reset</h3>
      </div>
      <div class="card-body">
        <form class="form" role="form" autocomplete="off">
          <div class="form-group">
            <label for="inputResetPasswordEmail">Email</label>
            <input type="email" class="form-control" id="inputResetPasswordEmail" required="">
            <span id="helpResetPasswordEmail" class="form-text small text-muted">
              Password reset instructions will be sent to this email address.
            </span>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-success btn-lg float-right">Reset</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "forgotPassword",
}

</script>