<template>
    <div id="Categories">
        <!-- Categories for the selected Tab / Menu Small ViewPorts -->
        <div v-if="$vuetify.breakpoint.smAndDown" class="mt-5 pr-5 pl-5">
            <!-- <v-select :items="getCategoryItemsForSelectedTab" v-model="activeCategory" item-value="name" item-text="name"
                :label="getSelectedCategoryName" solo dense active-class="black white--text" dark
                :menu-props="{ offsetY: true }" class="ml-4 mr-4" menu-prop:closeOnContentClick>
                <template v-slot:item="{ item }">
                    <v-list-item @click="selectCategoryAndClose(item)">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name" />
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select> -->
            <v-tabs-items v-model="activeTab">
                <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="i">
                    <BaseSelect :ListItems="tab.TabCategories" :activeCategory="activeCategory"
                        :selectItem="selectCategory" />
                </v-tab-item>
            </v-tabs-items>
        </div>
        <!-- Categories for the selected Tab / Menu Medium to Large ViewPorts -->
        <div v-else>
            <v-card>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item v-for="(tab, i) in tabs" :key="i" :value="i">
                        <v-list nav dense>
                            <h6 class="text-decoration-underline mb-2">
                                {{ tab.title }}
                            </h6>
                            <v-list-item-group v-model="activeCategory" active-class="black white--text" color="#000000">
                                <v-list-item v-for="(category, catIndex) in tab.TabCategories" :key="catIndex"
                                    @click="selectCategory(catIndex)" data-bs-toggle="tooltip" data-bs-html="true"
                                    title={category.tip}>
                                    <v-list-item-icon>
                                        <v-icon v-text="category.icon" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="category.name" />
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </div>
    </div>
</template>

<script>
import BaseSelect from '@/components/Base/BaseSelect.vue';

export default {
    props: {
        tabs: Array, // An array of tab objects with their properties
        activeTab: Number, // Pass the activeTab as a prop
        activeCategory: Number, // Pass the activeCategory as a prop
        getSelectedCategoryName: String, // Pass the computed property as a prop
        selectCategory: Function, // A function to select a category
    },
    components: {
        BaseSelect,
    },
    methods: {},
};
</script>

<style scoped>
.tooltip {
    position: absolute;
    z-index: 1020;
    display: block;
    visibility: visible;
    padding: 5px;
    font-size: 13px;
    opacity: 0;
    filter: alpha(opacity=0)
}

.tooltip.in {
    opacity: .8;
    filter: alpha(opacity=80)
}


.tooltip-inner {
    width: 350px;
    height: 30px;
    padding: 3px 8px;
    color: #314A5B;
    text-align: center;
    font-weight: 900;
    background: -webkit-gradient(linear, left top, left 25, from(#FFFFFF), color-stop(4%, #A2BBCC), to(#FFFFFF));
    background: -moz-linear-gradient(top, #FFFFFF, #A2BBCC 1px, #FFFFFF 25px);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 15px;
    border: 1px solid #314A5B;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0
}
</style>