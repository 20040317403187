<template>
  <div class="w-100 h-100 overflow-hidden">
    <!-- Account View for Small ViewPorts-->
    <v-select solo autofocus bottom label="Categories" :items="categories" item-text="name"
      active-class="black white--text" dark :menu-props="{ offsetY: true }" class="mt-5 mr-4 ml-4 md:pr-5 md:pl-5"
      v-if="$vuetify.breakpoint.smAndDown ? true : false" menu-prop:closeOnContentClick>
      <template slot="selected-option" slot-scope="categories">
        <v-list-item-group v-model="selectedItem">
          <v-list-item link :to="{ path: item.url }" v-for="(item, i) in categories" :key="i">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
      <template slot="option" slot-scope="option">
        <span class="fa" :class="option.icon"></span>
        {{ option.name }}
      </template>
    </v-select>

    <!--Account View for Medium and Large ViewPorts-->
    <v-row class="h-100">
      <!-- SideBar Navigation-->
      <v-col cols="12" md="3" sm="0" v-if="$vuetify.breakpoint.smAndDown ? false : true">
        <v-container class="position-fixed d-flex justify-start w-auto p-4 pr-0">
          <v-list dense class="centered-listed text-left w-100">
            <v-list-item-group v-model="selectedItem" active-class="black--text" color="black">
              <v-list-item link :to="{ path: item.url }" v-for="(item, i) in categories" :key="i"
                :disabled="i == 3 || i == 2">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-container>
      </v-col>
      <!--Right Section Components-->
      <v-col cols="12" md="9" sm="12" :class="[
        $vuetify.breakpoint.smAndDown ? 'm-auto' : 'p-0 pt-4',
        'overflow-hidden',
      ]">
        <div :class="$vuetify.breakpoint.smAndDown
          ? 'bg-light'
          : 'bg-light w-100 h-100 pt-5 border border-1 rounded-top-left'
          ">
          <Profile v-show="this.$route.path === '/account/profile'" />
          <Preferences v-show="this.$route.path === '/account/preferences'" />
          <Billing v-show="this.$route.path === '/account/billing'" />
          <Password v-show="this.$route.path === '/account/password'" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Profile from "@/components/MainBody/Account/Profile/Profile.vue";
import Billing from "@/components/MainBody/Account/Payments/Billing.vue";
import Preferences from "@/components/MainBody/Account/Preferences/Preferences.vue";
import Password from "@/components/Auth/Account/Password/Password.vue";

export default {
  name: "Account",

  components: {
    Profile,
    Billing,
    Preferences,
    Password,
  },

  data: () => ({
    selectedItem: 0,
    categories: [
      {
        name: "Profile",
        icon: "mdi-account",
        url: "/account/profile",
      },
      { name: "Preferences", icon: "mdi-drawing", url: "/account/preferences" },
      {
        name: "Billing",
        icon: "mdi-credit-card-outline",
        url: "/account/billing",
      },
      { name: "Password", icon: "mdi-lock", url: "/account/password" },
    ],
  }),

  methods: {},

  computed: {
    currentLink() {
      return this.categories[this.selectedItem].url;
    },
  },
};
</script>
<style scoped>
.divider {
  max-width: 240px !important;
}

.rounded-top-left {
  border-top-left-radius: 30px;
  border-top-right-radius: 0;
  border-color: #b8b9b9;
  border-width: 4px 0 0 4px;
}
</style>
