import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import IdleVue from "idle-vue";
import VueSweetalert2 from "vue-sweetalert2";
import VueTyperPlugin from "vue-typer";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

// Add the compositionAPI for vuejs2
import vueCompositionAPI from "@vue/composition-api";

//Bootstrap
import "bootstrap/dist/css/bootstrap.css";

//Flag Icons for Language Selector
import "/node_modules/flag-icons/css/flag-icons.min.css";

// What happens when the app is addle for a while?
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1800000, // 30 minutes
});

Vue.use(vueCompositionAPI);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2); // Vue.use(IconsPlugin)
Vue.use(VueTyperPlugin); // Typer Writer

Vue.config.productionTip = false;
// axios settings
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresLogin)) {
//     if (!store.getters.isAuthenticated) {
//       next({name: 'welcome'})
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
