import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import History from "@/components/MainBody/History/History.vue";
import Account from "@/components/MainBody/Account/Account.vue";
import Logout from "@/components/MainBody/Account/Logout.vue";
import Profile from "@/components/MainBody/Account/Profile/Profile.vue";
import EmailVerificationSent from "@/components/Auth/Account/Verification/EmailVerificationSent.vue";
import VerifyEmail from "@/components/Auth/Account/Verification/VerifyEmail.vue";
import Preferences from "@/components/MainBody/Account/Preferences/Preferences.vue";
import Billing from "@/components/MainBody/Account/Payments/Billing.vue";
import Welcome from "@/components/Auth/Account/Welcome.vue";
import Docs from "@/components/MainBody/Docs/Docs.vue";
import Support from "@/components/Footer/Support.vue";
import Password from "@/components/Auth/Account/Password/Password.vue";
import ForgotPassword from "@/components/Auth/Account/Password/ForgotPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: ["/home"],
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Facade.vue"),
  },

  // User history
  {
    path: "/history",
    name: "history",
    component: History,
  },
  // Support
  {
    path: "/support",
    name: "support",
    component: Support,
  },
  // User Account
  {
    path: "/account",
    name: "account",
    component: Account,

    children: [
      {
        path: "profile",
        component: Profile,
      },
      {
        path: "preferences",
        component: Preferences,
      },
      {
        path: "billing",
        component: Billing,
      },
      { path: "password", component: Password },
    ],
  },

  //Documentation
  {
    path: "/docs",
    name: "Documentation",
    component: Docs,
  },
  // Logout
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  // Email Verification
  {
    path: "/email-verification-sent",
    name: "EmailVerificationSent",
    component: EmailVerificationSent,
  },

  {
    path: "/verify-email",
    name: "verifyEmail",
    component: VerifyEmail,
    props: (route) => ({ verificationToken: route.query.token }),
  },

  // Sign UP & Log in
  {
    path: "/welcome",
    alias: ["/signin", "/signup"],
    name: "welcome",
    component: Welcome,
  },
  // Sign UP & Log in
  {
    path: "/forgot-password",
    alias: ["/passwordreset"],
    name: "forgotPassword",
    component: ForgotPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
