<template>
  <v-navigation-drawer clipped v-model="drawer" app width="210" class="nav_bar" permanent
    :mini-variant="$vuetify.breakpoint.mdAndDown" :expand-on-hover="$vuetify.breakpoint.mdAndDown">
    <!-- User Authenticated -->
    <v-list v-if="isAuthenticated">
      <v-list-item class="px-2 justify-center">
        <v-list-item-avatar :size="$vuetify.breakpoint.mdAndDown ? '50' : '80'"
          class="shadow-lg p-1 border border-2 border-white">
          <img src="https://i.pravatar.cc/" alt="avatar" />
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item link v-if="$vuetify.breakpoint.mdAndDown ? false : true">
        <v-list-item-content class="text-center p-0">
          <v-list-item-title class="text-h6">
            {{ userInfo.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ userInfo.email }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!--User Not Authenticated-->
    <!-- <v-list v-else>
      <v-list-item class="px-2 justify-center" @click="signIn">
        <v-list-item-avatar :size="$vuetify.breakpoint.mdAndDown ? '50' : '70'" class="m-0 p-0">
          <v-icon color :size="$vuetify.breakpoint.mdAndDown ? '40' : '60'">mdi-account-circle</v-icon>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item link v-if="$vuetify.breakpoint.mdAndDown ? false : true" class="m-0 py-0">
        <v-list-item-content class="text-center p-0">
          <v-list-item-title class="text-h6">Sign In</v-list-item-title>
          <v-list-item-subtitle class="text-wrap">You'll see your e-mail here</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
    <!-- Drawer Navigation -->
    <v-divider class="mt-0 pt-0" />
    <v-list nav dense>
      <v-hover v-slot="{ hover }">
        <v-list-item-group active-class="black white--text" :elevation="hover ? 20 : 0">
          <v-list-item link v-for="item in navDrawerFirstItems" :key="item.name" :to="{ path: item.path }"
            class="d-flex align-start" :disabled="item.disabled">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="align-start">{{
              item.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-hover>
    </v-list>
    <!-- Divider -->
    <v-divider class="mt-0 pt-0 mb-0" />
    <v-list nav dense>
      <v-hover v-slot="{ hover }">
        <v-list-item-group active-class="black white--text" :elevation="hover ? 20 : 0">
          <v-list-item link v-for="item in navDrawerSecondItems" :key="item.name" :to="{ path: item.path }"
            class="d-flex align-start" disabled>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="align-start">{{
              item.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-hover>
    </v-list>
    <!-- Divider -->
    <v-divider class="mt-0 pt-0" />
    <!-- Confirm Sign out -->
    <ConfirmSignOut class="list_item" />
    <!-- End of Confirm Sign out -->
    <v-list class="centered-list position-absolute bottom-0 start-0 auth-btn" v-if="!isAuthenticated">
      <v-list-item-group color="dark">
        <v-list-item @click="signIn">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-bold">Sign In</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import ConfirmSignOut from "@/components/Auth/Forms/ConfirmSignOut.vue";

export default {
  components: {
    ConfirmSignOut,
  },
  // computed: {
  //   ...mapState(["isAuthenticated", "userInfo"]),
  // },
  computed: mapState(["isAuthenticated", "userInfo"]),
  created() {
    this.$store.dispatch("authenticated");
  },

  data() {
    return {
      loader: null,
      loading2: false,
      drawer: null,
      loggedIn: true,
      navDrawerFirstItems: [
        { name: "Home", icon: "mdi-home", path: "/home", disabled: false },

        {
          name: "History",
          icon: "mdi-history",
          path: "/history",
          disabled: false,
        },
        {
          name: "My Account",
          icon: "mdi-account-star",
          path: "/account/profile",
          disabled: false,
        },
      ],
      navDrawerSecondItems: [
        {
          name: "Settings",
          icon: "mdi-cogs",
          path: "/settings",
          disabled: true,
        },
        {
          name: "NozaTax Docs",
          icon: "mdi-book-open",
          path: "/docs",
          disabled: true,
        },
      ],
    };
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },

  mounted() {
    let identiconScript = document.createElement("script");
    identiconScript.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/jdenticon@3.1.1/dist/jdenticon.min.js"
    );

    document.head.appendChild(identiconScript);
  },

  methods: {
    confirmSignOut() {
      console.log("Are you sure you want to Sign Out?");
      this.loggedIn = false;
    },

    signIn() {
      this.$router.push({ path: "/welcome" });
    },

    doSomething() { },
  },
};
</script>

<style scoped>
.nav_bar {
  background: rgba(206, 210, 210, 0.09);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.list_item {
  display: flex;
  align-self: start;
}

.auth-btn {
  width: 100% !important;
}
</style>
