<template>
  <div id="Home">
    <Scaffold />
  </div>
</template>

<script>
// @ is an alias to /src
import Scaffold from "@/components/MainBody/Home/Scaffold.vue";

export default {
  name: "Home",
  onIdle() {
    this.$store.dispatch("userLogout").then(() => {
      this.$router.push({ name: "welcome" });
    });
  },
  components: {
    Scaffold,
  },
};
</script>
<style></style>
