<template>
  <div id="Auth">
    <v-overlay :z-index="zIndex" :value="overlay" :opacity="opacity" :dark="dark" :color="color" :full-width="false">
      <v-container class="container mw-500" :class="{ 'sign-up-active': signUp }">
        <v-img :width="340" class="cover" src="../../../assets/images/cover.svg"></v-img>
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-left"></div>
            <div class="overlay-right"></div>
          </div>
        </div>
        <form class="sign-up" v-if="!loading">
          <h2 class="title">Registration</h2>
          <div class="w-50">
            <v-progress-linear indeterminate rounded buffer-value="0" stream value="100" color="green darken-2"
              v-show="showProgress"></v-progress-linear>
          </div>
          <div class="user-details">
            <article class="input-box">
              <label for="fullname">Full Name(Optional)</label>
              <article class="form-item" id="fullname">
                <input type="text" v-model.trim="fullname" placeholder="Full Name" />
                <!--
              <span
                v-if="!$v.fullname.required"
                class="invalid-feedback text-caption m-0 pl-4"
                align="left"
                >Full Name is required.</span
              > -->
                <!-- <span
                v-if="!$v.fullname.minLength"
                class="invalid-feedback text-caption m-0 pl-4"
                align="left"
                >Minimun of 4 characters.</span
              > -->
              </article>
            </article>
            <article class="input-box">
              <label for="username">Username(Optional)</label>
              <article class="form-item" id="username">
                <input type="text" v-model.trim="username" placeholder="Username" />
                <!-- <span
                v-if="!$v.username.required"
                class="invalid-feedback text-caption m-0 ml-4"
                align="left"
                >Username is required.</span
              >
              <span
                v-if="!$v.username.minLength"
                class="invalid-feedback text-caption m-0 ml-4"
                align="left"
                >Minimun of 4 characters.</span
              > -->
              </article>
            </article>
            <article class="input-box">
              <label for="email">Email</label>
              <article class="form-item" id="email">
                <input type="email" v-model.trim="$v.email.$model" placeholder="Email"
                  :class="validationStatus($v.email) ? 'is-invalid' : 'is-valid'" />
                <span v-if="!$v.email.required" class="invalid-feedback text-caption m-0 ml-4" align="left">Email is
                  required.</span>
                <span v-if="!$v.email.email" class="invalid-feedback text-caption m-0 ml-4" align="left">Invalid E-mail ||
                  i.e karegeya.sade@gmail.com</span>
              </article>
            </article>
            <article class="input-box">
              <label for="phone-number">Phone Number</label>
              <article class="form-item" id="phone-number">
                <input type="phone" v-model.trim="$v.phone.$model" placeholder="Phone" @input="phoneNumberMask()"
                  :class="validationStatus($v.phone) ? 'is-invalid' : 'is-valid'" />
                <span v-if="!$v.phone.required" class="invalid-feedback text-caption m-0 ml-4" align="left">Phone is
                  required.</span>
              </article>
            </article>
            <article class="input-box">
              <label for="password">Password</label>
              <article class="form-item" id="password">
                <input type="password" v-model="password" placeholder="Password" :class="validationStatus($v.password) ? 'is-invalid' : 'is-valid'
                  " required ref="passwordInput" />
                <span v-if="!$v.password.required" class="invalid-feedback text-caption m-0 ml-4" align="left">Password is
                  required.</span>
                <span v-if="!$v.password.minLength" class="invalid-feedback text-caption m-0 ml-4" align="left">Password
                  should be at least
                  {{ $v.password.$params.minLength.min }} characters.</span>
              </article>
            </article>
            <article class="input-box">
              <label for="confirmPassword">Confirm Password</label>
              <article class="form-item" id="confirmPassword">
                <input type="password" v-model="confirmPassword" placeholder="Confirm Password" :class="validationStatus($v.confirmPassword)
                  ? 'is-invalid'
                  : 'is-valid'
                  " required ref="passwordInput" />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="mdi mdi-eye-settings" :class="{ 'mdi mdi-eye-settings-off': showPassword }"></i>
                </span>
                <span v-if="!$v.confirmPassword.required" class="invalid-feedback text-caption m-0 ml-4"
                  align="left">Confirm Password is required.</span>
                <span v-if="!$v.confirmPassword.sameAsPassword" class="invalid-feedback text-caption m-0 ml-4"
                  align="left">Passwords don't match.</span>
              </article>
            </article>
            <!--
          <div class="gender-details">
            <input type="radio" name="gender" id="dot-1">
            <input type="radio" name="gender" id="dot-2">
            <input type="radio" name="gender" id="dot-3">
            <span class="gender-title">Gender</span>
            <div class="category">
              <label for="dot-1">
                <span class="dot one"></span>
                <span class="gender">Male</span>
              </label>
              <label for="dot-2">
                <span class="dot two"></span>
                <span class="gender">Female</span>
              </label>
              <label for="dot-3">
                <span class="dot three"></span>
                <span class="gender">Prefer not to say</span>
              </label>
            </div>
          </div>
          -->
          </div>
          <div class="d-flex flex-column">
            <button small @click.prevent="submitSignUp()" submit>Sign Up</button>
            <div class="d-flex flex-column align-center">
              <div class="switch">
                <p>Already have an account?</p>
                <button type="button" name="signIn" class="switch_button" id="signIn" @click="signUp = !signUp">
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </form>
        <form class="sign-in">
          <h2 class="title">Sign In</h2>
          <div>
            <v-progress-linear class="ma-2" indeterminate rounded buffer-value="0" stream value="100"
              color="green darken-2" v-show="loginProgress"></v-progress-linear>
          </div>
          <article class="input-box">
            <label for="email-login">Email</label>
            <article class="form-item" id="email-login">
              <input type="email" v-model.trim="$v.loginEmail.$model" placeholder="Email"
                :class="validationStatus($v.loginEmail) ? 'is-invalid' : ''" />
              <span v-if="!$v.loginEmail.required" class="invalid-feedback text-caption m-0 pl-4" align="left">Email is
                required.</span>
              <span v-if="!$v.loginEmail.email" class="invalid-feedback text-caption m-0 pl-4" align="left">Invalid e-mail
                address.</span>
            </article>
          </article>
          <article class="input-box">
            <label for="password-login">Password</label>
            <article class="form-item" id="password-login">
              <input :type="showPassword ? 'text' : 'password'" v-model.trim="$v.loginPassword.$model"
                placeholder="Password" :class="validationStatus($v.loginPassword) ? 'is-invalid' : ''" />
              <span v-if="!$v.loginPassword.required" class="invalid-feedback text-caption pl-4" align="left">Password is
                required.</span>
              <v-switch v-model="showPassword" label="Show password" class="text-caption p-2" color="#e3ad1a"
                dense></v-switch>
            </article>
          </article>
          <button @click.prevent="submitSignIn()" submit>Sign In</button>
          <div class="d-flex flex-column align-center flex-wrap">
            <router-link to="/forgot-password">Forgot your password?</router-link>
            <div class="switch">
              <p>New to NozaTax?</p>
              <button type="button" name="signUp" class="switch_button" id="signUp" @click="signUp = !signUp">
                Sign Up
              </button>
            </div>
          </div>
        </form>
      </v-container>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      overlay: true,
      zIndex: 5,
      opacity: 1,
      signUp: false,
      dark: false,
      color: "#DCDCDC",
      username: null,
      fullname: null,
      email: "",
      loginEmail: "",
      phone: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      loginPassword: "",
      loading: "",
      showProgress: false,
      loginProgress: false,
      submitStatus: null,
    };
  },
  validations: {
    // TODO: Move over the additional info page
    // fullname: { required, minLength: minLength(4) },
    // username: { required, minLength: minLength(4) },
    email: { required, email },
    loginEmail: { required, email },
    phone: { required },
    password: { required, minLength: minLength(3) },
    loginPassword: { required },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  created() { },
  methods: {
    userSignUp() {
      const formData = {
        name: this.username,
        full_name: this.full_name,
        email: this.email,
        phone: this.phone.replace(/[^\d]/g, ""),
        password: this.confirmPassword,
      };
      this.showProgress = !this.showProgress;
      axios
        .post("register/", formData)
        .then(() => {
          this.whoIsIt();
          this.clearForm();
          this.$router.push({ path: "/email-verification-sent" });
        })
        .catch((err) => {
          this.showProgress = !this.showProgress;
          if (err.response.status === 400) {
            this.submitStatus = "FAILED: BAD REQUEST";
            this.errorInSignUp(this.submitStatus);
          }
          if (err.response.status === 500) {
            this.submitStatus = "FAILED: SERVER ERROR";
            this.errorInSignUp(this.submitStatus);
          }
        });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
      const passwordInput = this.$refs.passwordInput; // Reference to the password input element
      if (this.showPassword) {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    userSignIn() {
      this.loginProgress = !this.loginProgress;
      this.$store
        .dispatch("userLogin", {
          loginEmail: this.loginEmail,
          loginPassword: this.loginPassword,
        })
        .then(() => {
          this.$store.dispatch("authenticated");
          // Get the redirect query parameter from the URL
          const redirectPath = this.$route.query.redirect || "/home";
          setTimeout(() => {
            this.$router.push({ path: redirectPath });
          }, 100);
        })
        .catch((err) => {
          this.loginProgress = false;
          if (err.response.status === 400) {
            this.submitStatus = "FAILED: BAD REQUEST";
            this.errorInSignIn(this.submitStatus);
          }
          if (err.response.status === 401) {
            this.submitStatus = "FAILED: UNAUTHORIZED";
            this.errorInSignIn(this.submitStatus);
          }
          if (err.response.status === 500) {
            this.submitStatus = "FAILED: SERVER ERROR";
            this.errorInSignIn(this.submitStatus);
          }
        });
    },

    submitSignIn() {
      this.$v.loginEmail.$touch();
      this.$v.loginPassword.$touch();
      if (this.$v.loginEmail.pending || this.$v.loginEmail.$error) return;
      if (this.$v.loginEmail.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        //submit logic
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.userSignIn();
        }, 500);
      }
    },

    submitSignUp() {
      // this.$v.fullname.$touch();
      // this.$v.username.$touch();
      this.$v.email.$touch();
      this.$v.phone.$touch();
      this.$v.password.$touch();
      this.$v.confirmPassword.$touch();
      if (
        this.$v.email.pending ||
        this.$v.email.error ||
        this.$v.password.$error ||
        this.$v.password.pending ||
        this.$v.confirmPassword.$error ||
        this.$v.confirmPassword.pending
      )
        return;
      if (this.$v.email.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        //submit logic
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.userSignUp();
        }, 500);
      }
    },

    whoIsIt() {
      localStorage.setItem("newComerEmail", this.email);
      localStorage.setItem("newComerUsername", this.username);
    },

    errorInSignIn(error) {
      this.$swal.fire({
        icon: "error",
        title: error,
        text: "Something went wrong!",
        confirmButtonText: "Try Again",
      });
    },

    errorInSignUp(error) {
      this.$swal.fire({
        position: "top-end",
        icon: "error",
        title: error,
        timer: 1500,
        text: "Something went wrong!",
        confirmButtonText: "Back",
      });
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    phoneNumberMask() {
      const x = this.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2] ? x[1] : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");
    },

    clearForm() {
      this.fullname = "";
      this.username = "";
      this.email = "";
      this.phone = "";
      this.password = "";
      this.confirmPassword = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

// form-textfield
.form-textfield {
  max-width: 240px !important;
}

.container {
  position: relative;
  width: 990px;
  height: 560px;
  border-radius: 5px;
  overflow-y: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
  background: #efefef;
}

.overlay-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
}

.overlay {
  height: 100%;
  width: 100%;
  background: var(--gradient-color);
  color: var(--background-color);
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

.cover {
  position: absolute;
  top: 100px;
  right: -10px;
  z-index: 2;
}

.overlay .cover :hover {
  transform: scaleX(-1);
}

@mixin overlays($property) {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  justify-content: end;
  padding: 100px 20px;
  text-align: center;
  transform: translateX($property);
  transition: transform 0.5s ease-in-out;
}

.overlay-left {
  @include overlays(-100%);
}

.overlay-right {
  @include overlays(0%);
}

p {
  margin: 20px 0;
}

a {
  color: #222;
  text-decoration: none;
  font-size: 0.9rem;
}

button {
  border-radius: 3px;
  border: 1px solid #333333;
  background-color: #333333;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: bolder;
  padding: 5px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.1s ease-in;
  align-self: center;

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: none;
  }
}

.switch_button {
  background: var(--gradient-color);
  border: none;
}

button.invert {
  background-color: transparent;
  border-color: #fff;
}

form {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  padding: 45px 80px;
  background: #fffafa;
  overflow: auto;
  transition: all 0.5s ease-in-out;

  .switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;

    p {
      margin: 5px;
      padding: 7px;
      font-size: 0.8rem;
      font-weight: bold;
    }
  }

  div {
    font-size: 1rem;
  }

  input {
    border: 1px solid #ccc;
    outline: none;
    font-size: 16px;
    padding-left: 15px;
    margin: 6px 0;
    width: 100%;
    height: 40px;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
    overflow: hidden;

    &:focus {
      outline: none;
      background-color: #ffffff;
    }
  }

  label {
    font-size: 15px;
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .input-box {
    display: relative;
    text-align: left;
    margin-bottom: 10px;
  }

  .input-box #confirmPassword #password {
    display: relative;
  }

  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

// For validation
.sign-up .is-invalid {
  border-bottom: 2px solid #ff2400;
}

.sign-in .is-invalid {
  border-bottom: 2px solid #ff2400;
}

.sign-up .is-valid {
  border-bottom-color: #13ca13e1;
}

.user-details .input-box input:focus {
  border-bottom-color: #e9ec10;
}

.form-item {
  width: 100%;
}

.sign-in {
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: initial;
  overflow: hidden;

  .title {
    position: relative;
    margin-bottom: 10px;
    font-size: 25px;
  }

  .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background: linear-gradient(135deg, #ffe000, #799f0c);
  }

  .input-box {
    width: 80%;
  }
}

.sign-up {
  left: 0;
  height: 100%;
  width: 82%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: initial;
  overflow: hidden;

  .title {
    font-size: 25px;
    font-weight: 500;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
  }

  .title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 30px;
    border-radius: 5px;
    background: linear-gradient(135deg, #ffe000, #799f0c);
  }

  .user-details {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 0px;
    grid-column-gap: 40px;
  }

  // gender-selection
  form .gender-title {
    font-size: 20px;
    font-weight: 500;
  }

  form .category {
    display: inline-flexbox;
    width: 80%;
    margin: 14px 0;
    justify-content: space-between;
    flex-direction: row;
  }

  form .category label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  form .category label .dot {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    background: #d9d9d9;
    border: 5px solid transparent;
    transition: all 0.3s ease;
  }

  #dot-1:checked~.category label .one,
  #dot-2:checked~.category label .two,
  #dot-3:checked~.category label .three {
    background: #9b59b6;
    border-color: #d9d9d9;
  }

  form input[type="radio"] {
    display: inline;
  }
}

.sign-up-active {
  .sign-in {
    transform: translateX(120%);
    display: none;
  }

  .sign-up {
    transform: translateX(0%);
    animation: fade-in 1s;

    @media screen and (max-width: 990px) {
      transform: translateX(0%);
    }
  }

  .overlay-left {
    transform: translateX(0%);
  }

  .overlay-right {
    transform: translateX(100%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// Smaller screens
@media only screen and (max-width: 990px) {
  .container {
    width: 450px;
    height: 490px;
    border-radius: 10px;
    overflow: scroll;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
    border-right: 10px solid #ffe000;

    .overlay-container {
      display: none;
    }

    .cover {
      display: none;
    }

    form {
      width: 100%;
      height: 100%;
      padding: 30px 35px;
      overflow: hidden;

      input {
        margin: 0;
      }

      button {
        font-size: 0.7rem;
        font-weight: medium;
        padding: 5px 35px;
      }

      .divider {
        display: none;
      }
    }

    .sign-up .input-box label {
      font-size: 13px;
    }

    .sign-up .input-box input {
      font-size: 13px;
      height: 35px;
      margin-bottom: 0;
    }
  }
}
</style>
