<template>
  <v-dialog v-model="dialog" @keydown.esc="dialog = false" transition="dialog-top-transition" max-width="600"
    :retain-focus="true" overlay-color="grey" overlay-opacity="1" v-if="isAuthenticated">
    <template v-slot:activator="{ on, attrs }">
      <v-list dense class="centered-list position-absolute bottom-0 start-0 mt-4 auth-btn">
        <v-list-item-group active-class="signout-button">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-bold">
              <h6>Sign Out</h6>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-slot:default="{}">
      <v-card class="text-center">
        <v-card-title class="text-h5 justify-center">
          Leave NozaTax?
        </v-card-title>
        <v-divider />
        <p>
          You can always sign back in at any time. We hope to see you back soon.
        </p>
        <v-card-actions class="justify-content-around">
          <v-btn @click="confirmSignOut" color="black text-white">Sign Out</v-btn>
          <v-btn outlined color="orange lighten-2" max-width="250" @click="dialog = false">Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ConfirmSignOut",
  props: {
    value: Boolean,
  },
  computed: mapState(["isAuthenticated"]),
  created() {
    this.$store.dispatch("authenticated");
  },
  data: () => ({
    dialog: "",
    loggedIn: true,
  }),

  methods: {
    confirmSignOut() {
      this.loggedIn = false;
      this.dialog = false;
      this.$router.push({ path: "/logout" });
    },
  },
};
</script>

<style>
.v-dialog .v-card .v-card__text .v-form .text-input .v-input__control .v-input__slot input {
  color: rgb(110, 107, 107);
}

.v-card {
  padding: 15px;
}

/* Input box border color/width */
.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: grey !important;
  border-width: 1px;
}

/* Input box border color/width when active */
.v-text-field .v-input__control .v-input__slot {
  color: #3b81f7;
}

.auth-btn {
  height: 65px;
  width: 100% !important;
}

.signout-button {
  color: orangered;
}
</style>
