<template>
    <div class="password-component container">
        <h5><span class="mdi mdi-checkbox-blank-circle h6 h-100 mr-2" />Password Security</h5>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="current-password">Current Password:</label>
                <input type="password" id="current-password" v-model="currentPassword" required />
            </div>
            <div class="form-group">
                <label for="new-password">New Password:</label>
                <input type="password" id="new-password" v-model="newPassword" required />
            </div>
            <div class="form-group">
                <label for="confirm-password">Confirm Password:</label>
                <input type="password" id="confirm-password" v-model="confirmPassword" required />
            </div>
            <button type="submit">Change Password</button>
        </form>
        <router-link to="/password-success">Go to Set New Password Successfully</router-link>
    </div>
</template>

<script>
export default {
    name: "Password",
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        };
    },
    methods: {
        submitForm() {
            // Add your logic to handle the form submission here
        }
    }
};
</script>

<style scoped>
.password-component {
    color: #000;
    padding: 30px;
    border-radius: 8px;
    font-size: 15px;
}

.password-component h5 {
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    font-size: 15px;
    display: block;
    margin-bottom: 8px;
}

input[type="password"] {
    width: 50%;
    padding: 10px;
    border: 1px solid #000;
    color: #fff;
    border-radius: 4px;
}

button[type="submit"] {
    background-color: #000;
    /* White background color */
    color: #fff;
    /* Black text color */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

button[type="submit"]:hover {
    background-color: #fff;
    /* Black background color on hover */
    color: #000;
    border: #000;
    /* White text color on hover */
}
</style>
