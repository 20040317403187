<template>
  <div class="download-item">
    <span class="download-icon mdi mdi-file-excel"></span>
    <!-- Excel icon -->
    <div class="download-details">
      <div class="download-title">Analysis Report</div>
      <div class="download-size">
        File Size: <span class="text-muted">100KB</span>
      </div>
    </div>
    <button class="download-button" @click="handleClick(downloadLink)">
      Download
    </button>
  </div>
</template>

<script>
export default {
  name: "OutputDownload",
  props: ["downloadLink"],
  components: {},
  data() {},
  methods: {
    handleClick(link) {
      const a = document.createElement("a");
      a.href = link;
      // Trigger a click event on the link element
      a.click();
      // this.$emit("click");
      // Remove the link element from the DOM (optional)
      document.body.removeChild(a);
    },
  },
};
</script>
<style lang="scss" scoped>
.download-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 20px;

  .download-icon {
    font-size: 54px;
    margin-right: 10px;
    padding: 0;
    color: #117243;
  }

  .download-details {
    flex-grow: 1;

    .download-title {
      font-weight: bold;
    }

    .download-size {
      color: #000000;
    }
  }

  .download-button {
    background-color: #41b619;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: darken(#41b619, 10%);
    }
  }
}
</style>
