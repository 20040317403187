<template>
  <v-row class="mb-10 w-100">
    <div>
      <v-progress-linear class="ma-2" indeterminate rounded buffer-value="0" stream value="100" color="green darken-2"
        v-show="showProgress"></v-progress-linear>
    </div>
    <p class="text-danger m-0" v-if="errorMessage">{{ errorMessage }}</p>
    <v-col cols="12" md="12" sm="12">
      <form class="form" enctype="multipart/form-data">
        <DropZone class="drop-area" @files-dropped="addFiles" #default="{ dropZoneActive }">
          <label for="file-input" class="{'subheading': $vuetify.breakpoint.mdAndDown}" v-if="loading">
            <v-icon x-large color="#000000" size="400">mdi-cloud-upload-outline</v-icon>
            <span v-if="dropZoneActive">
              <span class="drop-zone-text">Drop your files here</span>
              <span class="smaller drop-zone-text">to add them</span>
            </span>
            <span v-else>
              <span class="drop-zone-text">Drag & Drop Your Files Here</span>
              <span class="smaller drop-zone-text">
                or <strong><em>Click here</em></strong> to select files
              </span>
            </span>
            <input type="file" id="file-input" name="filesArray" multiple
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="onInputChange" />
          </label>
          <!-- <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-show="showProgress"
          ></v-progress-circular> -->
          <v-divider class="mt-4" v-show="filesArray.length" v-if="loading"></v-divider>
          <div class="file-list" v-show="filesArray.length" v-if="loading">
            <FilePreview v-for="file in filesArray" :key="file.id" :file="file" tag="div" @remove="removeFile" />
          </div>
        </DropZone>
      </form>
    </v-col>
    <v-col cols="12" md="12" sm="12" justify="right" align="right">
      <v-btn elevation="2" rounded-2 class="mb-2 white--text" color="#000000" @click="apiCall(tabName, categoryName)"
        v-if="!completed">Execute</v-btn>
    </v-col>
    <FileDownload v-if="completed && this.download_link != null" :downloadLink="this.download_link" />
  </v-row>
</template>

<script>
import axios from "axios";
// Testing out all the components
// import createUploader from "@/compositions/file-uploader";
import UploadableFiles from "@/utils/util";
import DropZone from "./DropZone.vue";
import FilePreview from "./FilePreview.vue";
import FileDownload from "./OutputDownload.vue";

export default {
  name: "Uploader",
  props: ["categoryName", "tabName"],
  components: {
    DropZone,
    FilePreview,
    FileDownload,
  },

  data() {
    return {
      files: [],
      filesArray: [],
      download_link: null,
      chip: true,
      loading: true,
      showProgress: false,
      completed: false,
      errorMessage: null,
    };
  },

  mounted() {
    // Checking out the breakpoints
    // console.log(this.$vuetify.breakpoint.width);
  },

  created() { },

  methods: {
    onInputChange(e) {
      this.addFiles(e.target.files);
      // this.filesArray = e.target.files;
      e.target.value = null;

      // If wrong file type upload is tryed
      this.errorMessage = "";
      for (let i = 0; i < e.target.files.length; i++) {
        if (!this.checkExtension(e.target.files[i])) {
          this.errorMessage =
            "Invalid file type. Please select Excel files only.";
          return;
        }
      }
    },

    addFiles(newFiles) {
      let newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFiles(file))
        .filter(
          (file) => !this.fileExists(file.id) && this.checkExtension(file)
        );
      // .filter((file) => this.wrongExtension(file));
      this.filesArray = this.filesArray.concat(newUploadableFiles);
    },

    // check if a file exists
    fileExists(otherId) {
      return this.filesArray.some(({ id }) => id === otherId);
    },

    removeFile(file) {
      const index = this.filesArray.indexOf(file);
      if (index > -1) this.filesArray.splice(index, 1);
    },

    checkExtension(file) {
      return (
        file.file.type === "application/vnd.ms-excel" ||
        file.file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    },

    /** API CAlls **/
    async apiCall(tab, categoryName) {
      let formData = new FormData();
      if (this.filesArray !== null) {
        this.filesArray.forEach((file) => {
          formData.append("files", file.file);
        });
        //TODO: additionalInfo: i.e Like output file looks, omitted columns..
        // formData.append("additionalInfo", JSON.stringify(additionalInfo));
      }
      this.showProgress = true;
      await axios
        .post(`${tab}/${categoryName}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        })
        .then((response) => {
          // Stop showing progress
          this.showProgress = false;
          // API call successful
          this.completed = true;
          this.download_link = response.data.analysis_file;
          // console.log("download_link", response.data.message);
        })
        .catch((error) => {
          // Stop showing progress
          this.showProgress = false;

          // API call error
          this.completed = false;
          this.errorMessage = error;
          // this.errorMessage = error.response.data.message;
        });
    },

    createOperation(files) {
      let formData = new FormData();

      files.forEach((file) => {
        formData.append("files", file.file);
      });

      axios
        .post("/nztx/v1/core/operation/create/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          // create a combined xlsx sales report
          // this.createCombinedSales();
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
  },
};

// Where to upload the files
// const { uploadFiles } = createUploader("@/images/");
</script>

<style lang="scss" scoped>
.drop-area {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  text-align: center;
  background: var(--background-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: 0.2s ease;

  &[data-active="true"] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background: #ffffff;
  }
}

label {
  font-size: 36px;
  cursor: pointer;
  display: block;

  span {
    display: block;
  }

  input[type="file"]:not(:focus-visible) {
    // Visually Hidden Styles taken from Bootstrap 5
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  .smaller {
    font-size: 16px;
  }
}

.image-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
}

.upload-button {
  display: block;
  appearance: none;
  border: 0;
  border-radius: 50px;
  padding: 0.75rem 3rem;
  margin: 1rem auto;
  font-size: 1.25rem;
  font-weight: bold;
  background: #369;
  color: #fff;
  text-transform: uppercase;
}
.file-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.drop-area {
  border: 0.1px solid #000000;
  box-shadow: 10px 10px 30px 5px rgba(66, 66, 66, 0.3);
  width: 100%;
}

.drop-zone-text {
  color: #000000;
  font-size: 4vh;
}

.download-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;

  .download-icon {
    font-size: 54px;
    margin-right: 10px;
    padding: 0;
    color: #117243;
  }

  .download-details {
    flex-grow: 1;

    .download-title {
      font-weight: bold;
    }

    .download-size {
      color: #000000;
    }
  }

  .download-button {
    background-color: #41b619;
    color: #ffffff;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: darken(#41b619, 10%);
    }
  }
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  /* Transparent Background Effect */
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
