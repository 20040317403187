<template>
  <v-app-bar app clipped-right clipped-left :height="$vuetify.breakpoint.smAndDown ? 30 : 40" class="nav_bar">
    <v-img src="../../assets/images/logotext.svg" :max-width="$vuetify.breakpoint.smAndDown ? 30 : 50" max-height="40" />
    <h1 class="logo">Noza<span class="nozatax">Tax</span></h1>
    <v-spacer />

    <v-btn icon class="mr-2 black--text" stacked>
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2" icon small v-bind="attrs" v-on="on">
            <v-badge dot color="error" label="notifications" offset-y="8" offset-x="5"><v-icon color="dark"
                :size="$vuetify.breakpoint.smAndDown ? 15 : 20">mdi-bell</v-icon></v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="i in 5" :key="i" @click="() => { }">
            <v-icon left light> mdi-wifi-strength-3-alert </v-icon>
            <v-list-item-title> Notification {{ i }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
    <LanguageSelector />
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import LanguageSelector from "@/components/Widgets/LanguageSelector.vue";
export default {
  components: {
    LanguageSelector,
  },

  data() {
    return {};
  },
  computed: {
    ...mapState(["isAuthenticated", "userInfo"]),
  },
  created() {
    this.$store.dispatch("authenticated");
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Cinzel Decorative";
  src: url("../../assets/fonts/CinzelDecorative-Bold.ttf") format("truetype");
}

.logo_img {
  @media (max-width: 576px) {
    width: 5px;
    height: 40px;
  }
}

.logo {
  font-family: Cinzel Decorative;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: -5px;
  padding-left: 5px;

  @media (max-width: 576px) {
    font-size: 0.8em;
    /* Change font-size to 0.6em for small viewports */
  }

  .nozatax {
    color: #e3ad1a;
  }
}
</style>
