<template>
  <!-- System bar -->
  <v-system-bar app dark class="p-0">
    <div class="slider-container">
      <div class="slider-text">
        <p>
          The New Paye calculation, as per the updated tax law, will be
          implemented starting in October 2023.
        </p>
      </div>
    </div>
  </v-system-bar>
</template>

<script>
export default {
  components: {},
  computed: {},
  created() {},
  data() {},
};
</script>
<style lang="scss" scoped>
.slider-container {
  min-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .slider-text {
    white-space: nowrap;
    animation: slide 60s ease-in-out 1s 2 alternate forwards;

    p {
      margin-bottom: 0;
      padding: 10px;
      color: #e3ad1a;
      font-size: 15px;

      @media screen {
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
