<template>
  <div class="p-0">
    <component :is="tag" class="file-preview">
      <v-chip v-if="chip" class="truncate-chip" close color="blue lighten-3"
        @click:close="(chip = false), $emit('remove', file)">
        {{ smartTrim(file.file.name, 30) }}
      </v-chip>
      <span class="status-indicator loading-indicator" v-show="file.status == 'loading'">In Progress</span>
      <span class="status-indicator success-indicator" v-show="file.status == true">Uploaded</span>
      <span class="status-indicator failure-indicator" v-show="file.status == false">Error</span>
    </component>
  </div>
</template>

<script>
export default {
  name: "FilePreview",
  emits: ["remove"],
  props: {
    file: { type: Object, required: true },
    tag: { type: String, default: "li" },
  },

  components: {},

  data() {
    return {
      chip: true,
    };
  },

  methods: {
    // Filename triming for files with long names(for some reason)
    smartTrim(str, maxLength) {
      // if (!str) return str;
      // if (maxLength < 1) return str;
      if (str.length <= maxLength) return str;
      // if (maxLength == 1) return str.substring(0, 1) + '...';
      let midpoint = Math.ceil(str.length / 2);
      let toRemove = str.length - maxLength;
      let lStrip = Math.ceil(toRemove / 2)
      let rStrip = toRemove - lStrip;
      return str.substring(0, midpoint - lStrip) + '...' + str.substring(midpoint + rStrip);
    }
  },
};
</script>

<style lang="scss" scoped>
.file-preview {
  height: fit-content;
  position: relative;

  .truncate-chip {
    min-width: 150px;
    /* Set the maximum width for the chip */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Add the ellipsis effect */
  }

  .close-icon,
  .status-indicator {
    --size: 25px;
    position: absolute;
    line-height: var(--size);
    height: var(--size);
    border-radius: var(--size);
    box-shadow: 0 0 5px currentColor;
    right: 0.25rem;
    appearance: none;
    border: 0;
    padding: 0;
  }

  .close-icon {
    width: var(--size);
    font-size: var(--size);
    background: rgb(163, 135, 135);
    color: #fff;
    top: 0.25rem;
    cursor: pointer;
  }

  .status-indicator {
    font-size: calc(0.75 * var(--size));
    bottom: 0.25rem;
    padding: 0 10px;
  }

  .loading-indicator {
    animation: pulse 1.5s linear 0s infinite;
    color: #000;
  }

  .success-indicator {
    background: #6c6;
    color: #040;
  }

  .failure-indicator {
    background: #933;
    color: #fff;
  }
}

@keyframes pulse {
  0% {
    background: #fff;
  }

  50% {
    background: #ddd;
  }

  100% {
    background: #fff;
  }
}
</style>
