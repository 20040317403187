<template>
    <v-tabs v-model="activeTab" color="#e3ad1a" align-with-title next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left"
        show-arrows grow optional>
        <v-tabs-slider color="#000000" />
        <v-tab v-for="(tab, i) in tabs" :key="i" @click="resetActiveCategory(i)" data-bs-toggle="tooltip"
            data-bs-placement="bottom" title="fdfadsf " :disabled="tab.disabled">
            {{ tab.title }}
        </v-tab>
    </v-tabs>
</template>

<script>

export default {
    props: {
        tabs: Array, // Pass in your tabs array as a prop
        activeTab: Number, // Pass in your activeTab as a prop
        disabled: Boolean,
        resetActiveCategory: Function,
    },
    methods: {},

};
</script>

<style>
.v-tabs .v-icon {
    font-size: 24px;
    /* Adjust the size as needed */
}
</style>