<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle dense class="ml-3">
        <v-btn color="white lighten-10" text x-small v-bind="attrs" v-on="on">
          <v-icon color="#e3ad1a" size="15">mdi-hammer-wrench</v-icon>
          {{ title }}
        </v-btn>
      </v-btn-toggle>
    </template>

    <v-card>
      <v-toolbar dark dense height="40px" flat tile>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon size="18">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <p class="page-title">{{ title }}</p>
      </v-container>
      <div id="contact-form" class="w-50 mx-auto">
        <v-container>
          <v-form @submit.prevent="createSupportTicket">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label for="name" class="input-label">Name</label>
                <v-text-field
                  id="name"
                  v-model="name"
                  outlined
                  color="black"
                  dense
                  shaped
                  placeholder="Name"
                  class="input-field"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label for="email" class="input-label">Email</label>
                <v-text-field
                  id="email"
                  v-model="email"
                  outlined
                  color="black"
                  dense
                  shaped
                  placeholder="Email"
                  class="input-field"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label for="type" class="input-label">Type</label>
                <v-select
                  id="type"
                  v-model="support_type"
                  :items="typeOptions"
                  outlined
                  color="black"
                  dense
                  shaped
                  placeholder="Type"
                  class="input-field"
                />
              </v-col>
              <v-col cols="12">
                <label for="message" class="input-label">Message</label>
                <v-textarea
                  id="message"
                  v-model="message"
                  outlined
                  color="black"
                  dense
                  shaped
                  placeholder="Message"
                  :rules="wordsRules"
                  counter="100"
                  class="input-field"
                />
              </v-col>
              <v-col cols="12">
                <button type="submit">Submit</button>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>

      <div class="bg-black w-100 pb-5 overflow-hidden">
        <p class="page-title text-white">Team</p>
        <v-row>
          <v-col v-for="card in cards" :key="card.id" class="card-list">
            <v-card class="card">
              <v-img :src="card.image" contain height="200" width="300" />
              <v-card-title class="card-title">{{ card.title }}</v-card-title>
              <v-card-text class="card-text">{{
                card.description
              }}</v-card-text>
              <v-card-actions class="v-card-actions">
                <p>Contacts</p>
                <v-spacer></v-spacer>
                <v-btn icon small @click="toggleContacts(card)">
                  <v-icon color="black">{{
                    card.showContacts ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
                <div v-show="card.showContacts" class="card-contacts">
                  <v-divider></v-divider>
                  <p><strong>Email:</strong> {{ card.contacts.email }}</p>
                  <p><strong>Phone:</strong> {{ card.contacts.phone }}</p>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Support",
  data: () => ({
    title: "Support",
    dialog: false,
    name: "",
    email: "",
    wordsRules: [],
    support_type: "",
    message: "",
    cards: [
      {
        id: 1,
        title: "Gilbert Izampa",
        description: "Project Director/Developer",
        image: "https://api.dicebear.com/6.x/avataaars/svg?seed=Garfield",
        showContacts: false,
        contacts: {
          email: "card1@example.com",
          phone: "123-456-7890",
        },
      },
      {
        id: 2,
        title: "Musoni Christian ",
        description: "Data Analyst/Developer",
        image: "https://api.dicebear.com/6.x/avataaars/svg?seed=Abby",
        showContacts: false,
        contacts: {
          email: "card1@example.com",
          phone: "123-456-7890",
        },
      },
      {
        id: 3,
        title: "Card 3",
        description: "Senior Advisor",
        image: "https://api.dicebear.com/6.x/avataaars/svg?seed=Gracie",
        showContacts: false,
        contacts: {
          email: "card1@example.com",
          phone: "123-456-7890",
        },
      },
      // Add more cards as needed
    ],
    //TODO: If there's a new support type,
    // remember to add to the backend Support Model as well.
    // Otherwise when used on the frontend, it'll result in an error.
    typeOptions: ["Issue", "Error"],
  }),
  methods: {
    createSupportTicket() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("message", this.message);
      formData.append("support_type", this.support_type.toLowerCase());

      if (this.message.trim() !== "") {
        // Call the API
        this.$store
          .dispatch("createSupportTicket", formData)
          .then((response) => {
            console.log(response);
            // Clear Support Message
            this.message = "";
          })
          .catch((error) => {
            if (error != null) {
              console.log(error);
            }
            // Clear Support Message
            this.name = "";
            this.message = "";
            this.email = "";
          });
      }
    },
    toggleContacts(card) {
      card.showContacts = !card.showContacts;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");

// Feel free to Removable
form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.mdi-hammer-wrench {
  color: #ffe000;
  height: 20px;
  width: 20px;
}

.input-label {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 10px;
}

.contact-form {
  .v-btn--dark {
    color: #000;
    background-color: #fff;
  }

  @media (max-width: 600px) {
    padding: 10px;
  }
}

.page-title,
.form-title {
  text-align: center;
  font-size: clamp(1.5rem, 5vw + 1rem, 5rem);
  font-family: Mansalva;
}

.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;

  .card {
    max-width: 300px;
    margin: 0 auto;

    .card-title {
      font-size: 20px;
      padding: 5px;
      margin: 0;
      color: #000;
    }

    .card-text {
      color: #616060;
      padding: 5px;
    }

    .v-card-actions {
      padding: 5px;
      display: flex;

      flex-wrap: wrap;

      p {
        font-size: 0.8em;
        text-align: right;
      }

      .card-contacts {
        padding-top: 10px;
        border-top: 1px solid #ccc;

        p {
          text-align: left;
        }
      }
    }
  }
}
</style>
