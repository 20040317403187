export const tabs = [
  {
    title: "Data Analytics",
    TabCategories: [
      {
        name: "Bulk Load(EBM/Etax)",
        icon: "mdi-file-multiple-outline",
        desc: "Descriptions that can be distinctive...",
      },
      { name: "EBM Data Analysis", icon: "mdi-network", desc: "" },
      { name: "Imports Analysis", icon: "mdi-airplane-landing", desc: "" },
      {
        name: "Inventory Analysis",
        icon: "mdi-archive-check-outline",
        desc: "",
      },
      { name: "Cost Analysis", icon: "mdi-cash-multiple", desc: "" },
      {
        name: "Etax & EBM Data Comparison",
        icon: "mdi-arrow-decision",
        desc: "",
      },
    ],
    tip: "Perform insightful data analysis on your dataset",
    disabled: false,
  },
  {
    title: "Tax Computation",
    TabCategories: [
      {
        name: "VAT(Value Added Tax)",
        icon: "mdi-file-multiple-outline",
        desc: "",
      },
      { name: "PAYE(Paye As You Earn) ", icon: "mdi-network", desc: "" },
      {
        name: "WHT (Witholding Tax)",
        icon: "mdi-airplane-landing",
        desc: "",
      },
      { name: "GAMING TAX", icon: "mdi-arrow-decision", desc: "" },
      {
        name: "CIT (Co-operate Income Tax)",
        icon: "mdi-archive-check-outline",
        desc: "",
      },
      {
        name: "PIT (Personal Income Tax)",
        icon: "mdi-cash-multiple",
        desc: "",
      },
      {
        name: "RSSB Contributions",
        icon: "mdi-assistant",
        subTabCategories: [
          { name: "Pension", icon: "mdi-cash-multiple" },
          { name: "Maternity", icon: "mdi-cash-multiple" },
          { name: "CBHI", icon: "mdi-cash-multiple" },
          { name: "Medical", icon: "mdi-cash-multiple" },
        ],
      },
    ],
    tip: "Compute Taxes with advanced tools ",
    disabled: false,
  },
  {
    title: "Tax Forms",
    TabCategories: [
      { name: "VAT(Value Added Tax)", icon: "mdi-file-multiple-outline" },
      { name: "PAYE(Paye As You Earn) ", icon: "mdi-network" },
      { name: "WHT (Witholding Tax)", icon: "mdi-airplane-landing" },
      { name: "GAMING TAX", icon: "mdi-arrow-decision" },
      {
        name: "CIT (Co-operate Income Tax) ",
        icon: "mdi-archive-check-outline",
      },
      { name: "PIT (Personal Income Tax)", icon: "mdi-cash-multiple" },
    ],
    tip: "Fill your Etax Annexure In real-time",
    disabled: false,
  },
  {
    title: "Bank Assessment",
    TabCategories: [
      { name: "Deposits", icon: "mdi-briefcase-upload" },
      { name: "Withdraws", icon: "mdi-cash" },
    ],
    tip: "Analysis and Reconciliation of Bank Transactions.",
    disabled: false,
  },
  {
    title: "Reports",
    TabCategories: [
      { name: "Income Tax", icon: "mdi-finance" },
      { name: "Value-Added-Tax", icon: "mdi-chart-line" },
      { name: "Receivables Details", icon: "mdi-account-box-outline" },
      { name: "Payables Details", icon: "mdi-account-details-outline" },
    ],
    tip: "Analysis and Reconciliation of Bank Transactions.",
    disabled: false,
  },
];
