<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card
          max-width="auto"
          elevation="8"
          class="pa-3 ma-4 overflow-auto"
          justify="space-between"
        >
          <!-- <table class="table">
            <thead>
              <tr>
                <th
                  v-for="item in columns"
                  :key="item.name"
                  :class="'text-' + item.align"
                >
                  <v-btn text class="p-0">
                    <h6 class="m-0 font-weight-bold">{{ item.name }}</h6>
                    <v-icon right>{{ item.icon }}</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rows" :key="index">
                <td class="left">{{ item.createdOn }}</td>
                <td class="left">{{ item.category }}</td>
                <td class="left">
                  <div
                    class="file-item"
                    v-for="(file, index) in item.inputFiles"
                    :key="index"
                  >
                    {{ smartTrim(file, 20) }}
                    <span right>
                      <v-btn icon x-small class="ml-2" color="blue lighten-2">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </span>
                  </div>
                </td>
                <td class="left">
                  <div
                    class="file-item"
                    v-for="(file, index) in item.outputFiles"
                    :key="index"
                  >
                    {{ smartTrim(file, 12) }}
                    <span right>
                      <v-btn icon x-small class="ml-2" color="black">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->
          <div>
            <!-- <v-pagination
              :length="totalNumberOfRows"
              v-model="currentPage"
              total-visible="5"
            ></v-pagination> -->

            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Occupation</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in filteredTableRows" :key="index">
                  <td>{{ row.name }}</td>
                  <td>{{ row.age }}</td>
                  <td>{{ row.occupation }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <HistoryPaginator
      :numberOfRows="totalNumberOfRows"
      :currentPage="currentPage"
      totalVisible="5"
      :onPageChange="onPageChange"
    ></HistoryPaginator>
  </v-container>
</template>

<script>
import HistoryPaginator from "./HistoryPaginator.vue";
export default {
  components: {
    HistoryPaginator,
  },

  props: {
    columns: Array,
    rows: Array,
    perpage: Number,
  },

  data() {
    return {
      tableRowsLength: 5,
      currentPage: 1,
      tableRows: [
        { name: "John Doe", age: 30, occupation: "Software Engineer" },
        { name: "Jane Doe", age: 25, occupation: "Doctor" },
        { name: "Peter Parker", age: 20, occupation: "Student" },
        { name: "Mary Jane Watson", age: 19, occupation: "Student" },
        { name: "John Doe", age: 30, occupation: "Software Engineer" },
        { name: "Jane Doe", age: 25, occupation: "Doctor" },
        { name: "Peter Parker", age: 20, occupation: "Student" },
        { name: "Mary Jane Watson", age: 19, occupation: "Student" },
        { name: "John Doe", age: 30, occupation: "Software Engineer" },
        { name: "Jane Doe", age: 25, occupation: "Doctor" },
        { name: "Peter Parker", age: 20, occupation: "Student" },
        { name: "Mary Jane Watson", age: 19, occupation: "Student" },
        { name: "John Doe", age: 30, occupation: "Software Engineer" },
        { name: "Jane Doe", age: 25, occupation: "Doctor" },
        { name: "Peter Parker", age: 20, occupation: "Student" },
        { name: "Mary Jane Watson", age: 19, occupation: "Student" },
        { name: "John Doe", age: 30, occupation: "Software Engineer" },
        { name: "Jane Doe", age: 25, occupation: "Doctor" },
        { name: "Peter Parker", age: 20, occupation: "Student" },
        { name: "Mary Jane Watson", age: 19, occupation: "Student" },
      ],
      // About Filtering
      formatRow: this.rows,
      sort: "Asc",
    };
  },

  created() {
    // format the value using the column's 'formatValue' method
    // this.onPageChange(1);
  },

  computed: {
    // return the total page number
    numberOfPages() {
      return Math.ceil(this.rows.length / this.perpage);
    },
    filteredTableRows() {
      // Filter out which rows get to be shown
      const startIndex = this.currentPage;
      const endIndex = Math.min(
        startIndex + this.tableRowsLength,
        this.tableRows.length
      );
      return this.tableRows.slice(startIndex, endIndex);
    },
    totalNumberOfRows() {
      // returns all the number of rows as per the tableRows
      return this.tableRows.length;
    },
  },

  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
    sortBy(prop) {
      if (this.sort === "Asc") {
        this.formatrow.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
        this.sort = "Desc";
      } else if (this.sort === "Desc") {
        this.formatrow.sort((a, b) => (a[prop] > b[prop] ? -1 : 1));
        this.sort = "Asc";
      }
    },
    // Filename triming for files with long names(for some reason)
    smartTrim(str, maxLength) {
      // if (!str) return str;
      // if (maxLength < 1) return str;
      if (str.length <= maxLength) return str;
      // if (maxLength == 1) return str.substring(0, 1) + '...';
      let midpoint = Math.ceil(str.length / 2);
      let toRemove = str.length - maxLength;
      let lStrip = Math.ceil(toRemove / 2);
      let rStrip = toRemove - lStrip;
      return (
        str.substring(0, midpoint - lStrip) +
        "..." +
        str.substring(midpoint + rStrip)
      );
    },
  },
};
</script>

<style scoped>
table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
  width: 100%;
}

th {
  border-bottom: 1px solid gray;
  text-align: left;
}

tr {
  border-bottom: 2px solid grey;
}

/* tr:hover {
  background-color: #f2f2f2;
} */
td.left {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

td.right {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

td.center {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.file-item:hover {
  background-color: #dcdcdc;
  border-radius: 10px;
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  height: 0.5em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-track:hover {
  background: rgb(68, 68, 68);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #616161;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e88e5;
}
</style>
