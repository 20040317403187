<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle dense class="ml-3">
        <transition name="wiggle" mode="out-in" :duration="10000">
          <v-btn
            color="white lighten-10"
            x-small
            icon
            v-bind="attrs"
            v-on="on"
            key="envelope-icon"
          >
            <v-icon class="wiggle-animation" color="#e3ad1a" size="20"
              >mdi-email-plus</v-icon
            >
          </v-btn>
        </transition>
      </v-btn-toggle>
    </template>

    <v-card>
      <v-toolbar dark dense height="40px" flat tile>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon size="18">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="suggestion-box">
        <div>
          <h2>Suggestion Box</h2>
        </div>
        <form @submit.prevent="submitSuggestion">
          <textarea
            v-model="message"
            placeholder="Enter your suggestion"
          ></textarea>
          <button type="submit">Submit</button>
        </form>

        <div class="suggestion-sent" v-if="message_sent == true">
          <p>Thanks for your suggestion. We appreciate you 💯!</p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from "axios";
export default {
  name: "SuggestionBox",
  data: () => ({
    title: "Suggestion Box",
    dialog: false,
    message_sent: false,
    message: "",
    suggestions: [],
  }),
  methods: {
    submitSuggestion() {
      let formData = new FormData();
      formData.append("message", this.message);
      if (this.message.trim() !== "") {
        this.suggestions.push(this.message);
        // Call the API with a suggestion
        this.$store
          .dispatch("createSuggestion", formData)
          .then(() => {
            (this.message_sent = true),
              // Clear suggestion Message
              (this.message = "");
          })
          .catch((error) => {
            if (error != null) {
              console.log(error);
            }
            // Clear suggestion Message
            this.message = "";
            this.message_sent = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }
}

.suggestion-sent {
  padding-top: 20px;
}

.wiggle-animation {
  animation: wiggle 3s 5;
}

.suggestion-box {
  border: 1px solid #ccc;
  padding: 20px;
  width: 600px;
  margin: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

ul {
  margin-top: 15px;
  list-style: none;
  padding-left: 0;
}

li {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}
</style>
