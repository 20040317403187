<template>
    <v-select :items="ListItems" v-model="activeItem" item-value="name" item-text="name" solo dense
        active-class="black white--text" dark :menu-props="{ offsetY: true, closeOnContentClick: true }" class="ml-4 mr-4">
        <template v-slot:item="{ item }">
            <v-list-item @click="selectItem(item)">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.name" />
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>
export default {
    props: {
        ListItems: Array, // Pass the List items as a prop
        activeItem: Number, //  // Pass the active Itme as a prop
        selectItem: Function //// A function to select an Item
    },
};
</script>
