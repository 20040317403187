<template>
    <v-col>
        <div class="dropdown" :class="{ open: isOpen }">
            <button class="dropdown-toggle" type="button" data-toggle="dropdown" @click="toggleDropdown">
                {{ selectedOption }}
            </button>
            <ul class="dropdown-menu">
                <li class="dropdown-item" v-for="(option, index) in options" :key="index">
                    <a @click="selectOption(option)">{{ option }}</a>
                </li>
            </ul>
        </div>
    </v-col>
</template>

<script>
export default {
    name: 'TinSelector',
    components: {},
    data: () => ({
        selectedOption: "Select Tin",
        options: ["Option 1", "Option 2", "Option 3"],
        isOpen: false,
    }),
    methods: {
        selectOption(option) {
            this.selectedOption = option;
            this.isOpen = false; // Close the dropdown when an option is selected
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen; // Toggle the dropdown open/closed state
        },
    },
    computed: {}

}
</script>
<style lang="scss" scoped>
.dropdown {
    position: relative;
    display: inline-block;
    font-size: small;
}

.dropdown-toggle {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.dropdown-toggle:hover {
    outline: none;
    border: 2px solid #000;

}

.dropdown-toggle:focus {
    background-color: #000;
    color: #fff;
}

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 0;
    margin: 0;
    z-index: 1;
}

.open .dropdown-menu {
    display: block;
    margin-top: 4px;
}

.dropdown-item {
    list-style: none;
    font-size: small;
}

.dropdown-item:active {
    background: #333;
    color: #fff;
}

.dropdown-item a {
    display: block;
    padding: 5px 10px;
    color: #000;
    text-decoration: none;
}

.dropdown-item a:hover {
    font-weight: bold;
}
</style>