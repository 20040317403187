<template>
  <div class="container main-container">
    <h5 class="font-weight-medium align-items-center mb-0"><span
        class="mdi mdi-checkbox-blank-circle h6 h-100 mr-2"></span>Preferences
    </h5>
    <small class="m-0 pl-5 text-muted">Manage Your Account Preferences</small>
    <div class="m-3 border border-1 " />

    <div class="card-items">
      <v-row>
        <v-col cols="12" md="8" sm="8">
          <div>
            <div class="d-flex flex-row align-items-center justify-content-around ">
              <p class="flex-fill m-0">Receive Newsletter</p>
              <v-switch v-model="switch1" value="switch1" hide-details color="#e3ad1a" class="mt-0 p-0" dense></v-switch>
            </div>
            <p>
              <small class="m-0 p-0 text-muted">Choose to Receive Newletter Updates continously</small>
            </p>
          </div>
          <div>
            <div class="d-flex flex-row align-items-center justify-content-around">
              <p class="flex-fill m-0">Sign me up for updates</p>
              <v-switch v-model="switch2" value="switch2" hide-details color="#e3ad1a" class="mt-0 p-0" dense></v-switch>
            </div>
            <p>
              <small class="m-0 p-0 text-muted">Activate to automatically Apply New Updates</small>
            </p>
          </div>
          <div>
            <div class="d-flex flex-row align-items-center justify-content-around">
              <p class="flex-fill m-0">Automated Reporting</p>
              <v-switch v-model="switch3" value="switch2" hide-details color="#e3ad1a" class="mt-0 p-0" dense></v-switch>
            </div>
            <p>
              <small class="m-0 p-0 text-muted">Generate reports to my email on a weekly, monthly, or yearly
                basis.</small>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div class="text-left">
          <v-btn elevation="2" rounded-2 class="white--text" color="#000000">Save</v-btn>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      switch1: false,
      switch2: false,
      switch3: false,
    }
  }
}
</script>
<style scoped>
.main-container {
  height: inherit;
  overflow-y: scroll;
}

.card-items {
  height: 100%;
  padding: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: #000000;
}
</style>