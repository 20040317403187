<template>
  <!-- Populating the table with props -->
  <!-- <DataTable :columns="columns" :rows="campaigns" :perpage="6"/> -->
  <div class="w-100 h-100 overflow-hidden">
    <HistoryFilter />
    <DataTable :columns="tableColumns" :rows="tableRows" />
  </div>
</template>

<script>
import HistoryFilter from "./HistoryFilter.vue";
import DataTable from "./DataTable.vue";

export default {
  components: {
    DataTable,
    HistoryFilter,
  },

  data: () => ({
    dialog: false,

    tableColumns: [
      {
        name: "Date",
        align: "left",
        icon: "mdi-sort",
      },
      {
        name: "Category",
        align: "left",
        icon: "mdi-sort",
      },
      {
        name: "Input File(s)",
        align: "left",
        icon: "mdi-file",
      },
      {
        name: "Output",
        align: "left",
        icon: "mdi-export",
      },
    ],
    tableRows: [
      {
        createdOn: "2022-05-16",
        category: "Bulk Load(EBM/Etax)",
        inputFiles: [
          "inputFile01.xlsx",
          "inputFile02.xlsx",
          "inputFile03.xlsx",
        ],
        outputFiles: ["outputFile01.xlsx"],
      },
      {
        createdOn: "2022-05-22",
        category: "Etax & EBM Data Comparison",
        inputFiles: [
          "EBM_and_Etax_inputFile01.xlsx",
          "EBM_and_Etax_inputFile02.xlsx",
          "EBM_and_Etax_inputFile03.xlsx",
        ],
        outputFiles: ["EBM_and_Etax_outputFile01.xlsx"],
      },
    ],
  }),

  computed: {},

  watch: {
    // Date Range
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },

  created() {},

  methods: {
    // Date Range
    saveDate(date) {
      this.$refs.menu.save(date);
    },

    downloadItem() {},
  },
};
</script>
