<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" justify="center" align="center">
      <v-card max-width="480" elevation="4">
        <v-card-text>
          <div>
            <v-avatar :size="$vuetify.breakpoint.mdAndDown ? '70' : '90'" color="black">
              <v-icon x-large color="white">mdi-email-check</v-icon>
            </v-avatar>
            <v-divider class="divider"></v-divider>
          </div>
          <!-- <p>Token: {{ verificationToken }}</p> -->

          <h3 color="green">You e-mail has been successfully verified!</h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn depressed text color="orange" @click="goBack()">Sign Me In</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["verificationToken"],
  data() {
    return {
      username: "",
      email: "",
    };
  },
  created() {
    this.username = localStorage.getItem("newComerUsername");
    this.email = localStorage.getItem("newComerEmail");
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/welcome" });
    },
  },
};
</script>

<style scoped>
.divider {
  width: 300px !important;
  color: #3b81f7 !important;
}

.verification-email {
  cursor: pointer;
}

.verification-email:hover {
  text-decoration: underline;
}
</style>
