<template>
  <v-app class="min-vw-100 min-vh-100">
    <Navigation />
    <v-main class="main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation/Navigation.vue";
import axios from "axios";
import "./assets/styles/custom.css";

export default {
  name: "App",
  components: {
    Navigation,
  },

  mounted() {
    // console.log(process.env.VUE_APP_API_URL);
    setInterval(() => {
      this.$store.dispatch("refreshToken");
    }, 3540000); // 59 mins.
  },

  beforeCreate() {
    this.$store.commit("initStore");
    const accessToken = this.$store.state.accessToken;
    const refreshToken = this.$store.state.refreshToken;
    if (accessToken && refreshToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },

  data: () => ({
    //
  }),
};
</script>