<template>
  <div class="inspire">
    <!-- System bar -->
    <SystemBar></SystemBar>

    <!-- Navigation Bar -->
    <NavBar></NavBar>

    <!-- Navigation Drawer -->
    <NavigationDrawer></NavigationDrawer>

    <!-- Footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SystemBar from "@/components/Widgets/SytemBar.vue";
import NavBar from "@/components/Navigation/NavBar.vue";
import NavigationDrawer from "@/components/Navigation/NavigationDrawer.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  components: {
    SystemBar,
    NavBar,
    NavigationDrawer,
    Footer,
  },

  computed: {
    ...mapState(["isAuthenticated", "userInfo"]),
  },

  created() { },

  data() {
    return {
      loader: null,
      loading2: false,
    };
  },

  methods: {
    doSomething() { },
  },
};
</script>
