<template>
    <div class="language-selector">
        <span class="fi fi-gb border border-1 rounded-pill"></span>
        <select v-model="selectedLanguage">
            <option v-for="(language, code) in languages" :value="code" :key="code">
                <span :class="['fi fi-${code} rounded-pill']"></span>{{ language }}
                <span :class="['flag-icon', `fi-${code}`]"></span>
            </option>
        </select>
    </div>
</template>

<script>
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default {
    data() {
        return {
            selectedLanguage: 'gb', // Default language is English
            languages: {
                gb: 'en',
                rw: 'rw',
                fr: 'fr',
            },
        };
    },
    methods: {},
    watch: {
        selectedLanguage() {
            // Implement code to handle language change here
            // You can use this.selectedLanguage to load language-specific content or trigger language switch functionality.
            console.log(`Selected language: ${this.selectedLanguage}`);
        },
    },
};
</script>

<style scoped>
.language-selector {
    font-size: 15px;
}

.flag-icon {
    width: 24px;
    /* Adjust the size as needed */
    height: 16px;
    margin-right: 5px;
}
</style>
