<template>
  <v-card>
    <v-toolbar dark dense height="40px" flat tile>
      <v-toolbar-title>Documentation</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: "Docs",
  data: () => ({
    dialog: false,
  }),
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");

body {
  /* Created with https://www.css-gradient.com */
  background: #23ec55;
  background: -webkit-radial-gradient(top right, #23ec55, #2d51c1);
  background: -moz-radial-gradient(top right, #23ec55, #2d51c1);
  background: radial-gradient(to bottom left, #23ec55, #2d51c1);
}

.page-title {
  text-align: center;
  font-size: 5em;
  font-family: Mansalva;
}

.wrapper {
  margin: 10vh;
}

.card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  min-height: 450px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    min-height: 350px;
  }

  @media (max-width: 420px) {
    min-height: 300px;
  }

  &.card-has-bg {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      -webkit-filter: grayscale(1);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &:hover {
      transform: scale(0.98);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 130%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgb(35, 79, 109);
        background: linear-gradient(
          0deg,
          rgba(4, 69, 114, 0.5) 0%,
          rgba(4, 69, 114, 1) 100%
        );
      }
    }
  }

  .card-footer {
    background: none;
    border-top: none;

    .media {
      img {
        border: solid 3px rgba(255, 255, 255, 0.3);
      }
    }
  }

  .card-meta {
    color: #26bd75;
  }

  .card-body {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    .card-body {
      margin-top: 30px;
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(35, 79, 109);
    background: linear-gradient(
      0deg,
      rgba(35, 79, 109, 0.3785889355742297) 0%,
      rgba(69, 95, 113, 1) 100%
    );
  }
}

.input {
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 8px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s;
  font-family: "Cookie", cursive;

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

.label {
  color: #000;
  margin-bottom: 8px;
  font-weight: bold;
  font-family: "Cookie", cursive;
}

.button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #333;
  }
}
</style>
