<template>
  <div class="container">
    <h5 class="font-weight-medium align-items-center"><span
        class="mdi mdi-checkbox-blank-circle h6 h-100 mr-2"></span>Payments &amp; Subscriptions
    </h5>
    <div class="card-items">
      <div class="card-items sub-card rounded border-warning border-top border-5">
        <h5>NozaTax Plus</h5>
        <p class="text-primary">SUBSCRIBED <span class="text-black text-lowercase">On: </span><span
            class="text-success text-capitalise">{{
              formattedDateTime }}</span>
        </p>
        <p>Next Charge On: <span class="text-success text-capitalise">12th April 2024</span>
        </p>
        <h2 class="text-indigo">5,000 <span class="text-muted">Rwf</span></h2>
        <ul class="list-unstyled p-0 :hover">
          <li><a href="" class="link-text">Change Plan</a></li>
          <li><a href="" class="link-text">Get Receipts</a></li>
          <li><a href="" class="link-text ">Cancel Subscription</a></li>
        </ul>
      </div>
    </div>
    <div class="card-items">
      <v-row>
        <v-col cols="12" md="12" sm="12" justify="left" align="left">
          <h6>Payment history</h6>
          <v-divider></v-divider>
          <div class="table-container">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice</th>
                  <th>Billing Period</th>
                  <th>Payment Method</th>
                  <th>Amount</th>

                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2023-09-01</td>
                  <td>INV-001</td>
                  <td>August 2023</td>
                  <td>Credit Card</td>
                  <td>10,000 Rwf</td>

                  <td><button class="receipt-btn">View</button></td>
                </tr>
                <tr>
                  <td>2023-09-05</td>
                  <td>INV-002</td>
                  <td>August 2023</td>
                  <td>Momo Pay</td>
                  <td>5000 Rwf</td>

                  <td><button class="receipt-btn">View</button></td>
                </tr>
                <!-- Add more rows as needed -->
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "Subscription",
  components: {},
  data: () => ({
    items: [
      {
        action: "mdi-calendar",
        items: [{ title: "No Pending Subscription" }],
        title: "This Month",
        // active: true
      },
      {
        action: "mdi-calendar",
        items: [{ title: "2022-04-01 ~ 2022-05-01" }],
        title: "April",
      },
      {
        action: "mdi-calendar",
        items: [
          { title: "2022-03-01 ~ 2022-04-01" },
          { title: "2022-03-02 ~ 2022-04-02" },
        ],
        title: "March",
      },
    ],
    formattedDateTime: '',
  }),
  methods: {
    displayDateTime() {
      const currentDateTime = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      this.formattedDateTime = currentDateTime.toLocaleDateString('en-US', options);
    },
  },
  mounted() {
    this.displayDateTime();
  },
};
</script>
<style lang="scss" scoped>
.card-items {
  padding: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  color: #000000;

  .sub-card {
    min-width: 320px;
    width: 60%;
    padding: 32px;
    background-color: #ffffff;

    .link-text {
      text-decoration: none;
      transition: opacity 0.3s ease-in-out;
    }

    .link-text:hover {
      text-decoration: underline;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }

  .table-container {
    overflow-x: auto;
    max-width: 100%;
  }

  .receipt-btn {
    text-decoration: underline;
    text-align: center;
  }
}
</style>