import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: "",
    refreshToken: "",
    isAuthenticated: false,
    userInfo: null,
  },
  mutations: {
    initStore(state) {
      if (
        localStorage.getItem("accessToken") &&
        localStorage.getItem("refreshToken")
      ) {
        state.accessToken = localStorage.getItem("accessToken");
        state.refreshToken = localStorage.getItem("refreshToken");
        state.isAuthenticated = true;
      } else {
        state.accessToken = "";
        state.refreshToken = "";
        state.isLoading = false;
      }
    },

    updateToken(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    },

    deleteToken(state) {
      state.accessToken = "";
      state.refreshToken = "";
      state.isAuthenticated = false;
      localStorage.setItem("accessToken", "");
      localStorage.setItem("refreshToken", "");
      axios.defaults.headers.common["Authorization"] = "";
    },

    authStatus(state) {
      state.isAuthenticated = true;
    },

    createSuggestion() {},
  },
  getters: {
    isAuthenticated(state) {
      return state.accessToken != "";
    },
  },
  actions: {
    fetchHistory() {},
    fetchTheme() {},
    lookUpAnItem() {},
    // Sign Up - NewsLetter
    signUpNewsLetter(context, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("news-letter/", {
            email: formData.email,
          })
          .then((response) => {
            console.log(response);
            // User is now Authenticated?
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Refresh Token
    refreshToken(context) {
      return new Promise((resolve, reject) => {
        axios
          .post("token/refresh/", {
            refresh: context.state.refreshToken,
          })
          .then((response) => {
            console.log("New AccessToken successfully generated");

            context.commit("updateToken", {
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
            });

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access;
            localStorage.setItem("accessToken", response.data.access);
            localStorage.setItem("refreshToken", response.data.refresh);
            resolve();
          })
          .catch((err) => {
            console.log("REFRESH TOKEN: " + context.state.refreshToken);
            reject(err);
          });
      });
    },

    // User Logout
    userLogout(context) {
      if (context.getters.isAuthenticated) {
        context.commit("deleteToken");
      }
    },

    userLogin(context, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("login/", {
            email: formData.loginEmail,
            password: formData.loginPassword,
          })
          .then((response) => {
            context.commit("updateToken", {
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
            });
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access;
            localStorage.setItem("accessToken", response.data.access);
            localStorage.setItem("refreshToken", response.data.refresh);

            // User is now Authenticated?
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    verifyEmail(context, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("login/", {
            email: formData.loginEmail,
          })
          .then((response) => {
            context.commit("updateToken", {
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
            });
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access;
            localStorage.setItem("accessToken", response.data.access);
            localStorage.setItem("refreshToken", response.data.refresh);

            // User is now Authenticated?

            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    authenticated(context) {
      this.state.isAuthenticated = context.getters.isAuthenticated;
      if (this.state.isAuthenticated) {
        axios
          .get("auth/")
          .then((response) => {
            this.state.userInfo = response.data;
            let identiconScript = document.createElement("script");
            identiconScript.setAttribute(
              "src",
              "https://cdn.jsdelivr.net/npm/jdenticon@3.1.1/dist/jdenticon.min.js"
            );
            document.head.appendChild(identiconScript);
          })
          .catch((error) => {
            console.log(error.response.status);
          });
      }
    },

    // Suggestion
    async createSuggestion(context, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("mngt/suggest/", formData)
          .then((response) => {
            console.log(response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Support
    createSupportTicket(context, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("mngt/support/", formData)
          .then(() => {
            // console.log(response.data);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});
