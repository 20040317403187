<template>
  <div class="p-5">
    <v-card class="bg-light">
      <div class="profile">
        <div class="header">
          <!--User Header Image-->
          <img
            class="header-image"
            src="https://random.imagecdn.app/500/150"
            alt="Header Image"
          />
        </div>
        <div class="user-profile">
          <!--User Profile Picture-->
          <div
            class="profile-picture shadow-lg p-1 border border-2 border-white"
          >
            <img src="https://i.pravatar.cc/" alt="Profile Picture" />
          </div>
          &nbsp;
        </div>
        <div class="profile-edit" data-hint="Edit Profile">
          <v-icon size="25" color="black">mdi-account-edit</v-icon>
        </div>
        <!--User Profile Fullname and Username-->
        <div class="profile-user">
          <p class="profile-fullname">{{ userInfo.full_name }}</p>
          <p class="profile-username">@{{ userInfo.name }}</p>
        </div>
        <!--User Bio-->
        <p class="profile-bio">
          <span class="profile-bio-title">Bio: </span>Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Nulla ac tempor nulla, et
          sollicitudin eros. Maecenas vel sem magna.
        </p>
        <!--User Email, Address and Occupation-->
        <div class="pr-50 pl-50 w-80">
          <ul class="profile-details">
            <li>
              <v-icon size="25" color="black">mdi-email-variant</v-icon>
              &nbsp;<strong>Email:</strong> {{ userInfo.email }}
            </li>
            <li>
              <v-icon size="25" color="black">mdi-phone</v-icon> &nbsp;<strong
                >Phone number:</strong
              >
              {{ userInfo.phone }}
            </li>
            <li>
              <v-icon size="25" color="black">mdi-map-marker</v-icon>
              &nbsp;<strong>Address:</strong> Nyarugenge, KIGALI
            </li>
            <li>
              <v-icon size="20" color="black">mdi-briefcase-check</v-icon>
              &nbsp;<strong>Occupation:</strong> Auditor
            </li>
          </ul>
        </div>
      </div>
      &nbsp;
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "personalInfo",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      showEdit: false,
    };
  },
  computed: mapState(["isAuthenticated", "userInfo"]),
  created() {
    this.$store.dispatch("authenticated");
  },
  methods: {
    updateAccount() {},
  },
  mounted() {
    let identiconScript = document.createElement("script");
    identiconScript.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/jdenticon@3.1.1/dist/jdenticon.min.js"
    );

    document.head.appendChild(identiconScript);
  },
};
</script>

<style lang="scss">
.profile {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;

  .header {
    border-start-start-radius: 10px;
    border-start-end-radius: 10px;

    .header-image {
      max-height: 160px;
      width: 100%;
      border-start-start-radius: 5px;
      border-start-end-radius: 5px;
    }
  }

  .user-profile {
    position: relative;
    background-color: none;

    .profile-picture {
      position: absolute;
      top: -80px;
      left: 42%;
      max-width: 130px;
      height: auto;
      border: 4px solid #fff;
      border-radius: 50%;

      img {
        width: auto;
        height: 100px;
        border-radius: 50%;
      }
    }
  }

  .profile-edit::before {
    content: "";
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 6px;
    border-color: transparent transparent rgba(0, 0, 0, 1) transparent;
    visibility: hidden;
  }

  .profile-edit {
    width: fit-content;
    margin-left: auto;
    margin-right: 20px;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 20px #929292;
  }

  .profile-edit::after {
    content: attr(data-hint);
    position: absolute;
    top: 125%;
    left: 0%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
  }

  .profile-edit:hover {
    animation: bounce 1s infinite;
  }

  .profile-edit:hover::before,
  .profile-edit:hover::after {
    opacity: 1;
    visibility: visible;
  }

  .profile-user {
    text-align: center;
    margin-left: 33px;

    .profile-fullname {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0;
    }

    .profile-username {
      margin-top: 0;
      font-size: 15px;
    }
  }

  .profile-bio {
    font-size: 14px;
    margin-bottom: 15px;
    text-wrap: balance;

    .profile-bio-title {
      font-weight: bold;
    }
  }

  ul.profile-details {
    list-style-type: none;
    align-items: start;
    align-content: start;

    li::before {
      display: none;
    }

    li {
      margin-bottom: 5px;

      strong {
        font-weight: bold;
      }
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-10px);
    }

    60% {
      transform: translateY(-5px);
    }
  }
}
</style>
