<template>
  <v-layout row warp>
    <v-pagination
      v-model="localCurrentPage"
      :length="numberOfRows"
      :total-visible="totalVisible"
      color="black"
      circle
      @input="onPageChange"
    ></v-pagination>
  </v-layout>
</template>
<script>
export default {
  props: {
    numberOfRows: Number,
    currentPage: Number,
    totalVisible: String,
    onPageChange: Function,
  },
  components: {},
  data() {
    return {
      localCurrentPage: this.currentPage,
    };
  },
  watch: {},
  methods: {},
};
</script>
