<template>
  <!-- Footer -->
  <v-footer app dark class="p-0" height="30px">
    <v-row>
      <v-col class="py-0 mb-3">
        <v-card class="d-flex justify-start py-0">
          <v-card-actions class="py-0">
            <Support />
            <br />
            <SuggestionBox />
            <!-- <Docs /> -->
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="py-0">
        <v-card-text dark class="d-flex justify-end py-0 white--text">
          <strong>© <span class="logo">Noza<span class="nozatax">Tax</span></span>&nbsp;</strong>
          2023
        </v-card-text>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import Support from "./Support.vue";
import SuggestionBox from "./SuggestionBox.vue";
// import Docs from "@/components/Layout/Footer/Docs.vue";
export default {
  components: {
    Support,
    SuggestionBox,
  },
  setup() { },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "Cinzel Decorative";
  src: url("../../assets/fonts/CinzelDecorative-Bold.ttf") format("truetype");
}

.logo {
  font-family: Cinzel Decorative;
  font-size: 0.7em;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 5px;

  .nozatax {
    color: #e3ad1a;
  }
}
</style>
