<template>
  <v-card class="card-decorator">
    <div class="filter-container">
      <v-row align="center">
        <v-col cols="12" md="5" sm="6" class="ph-10">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                block
                elevation="2"
                class="m-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left color="black">mdi-chevron-down</v-icon>Choose
                Category
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="black">
                <v-list-item
                  v-for="(category, index) in categories"
                  :key="index"
                  @click="sortByCategory(category.name)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="category.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-left">{{
                    category.name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" md="5" sm="6" class="ph-6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                append-icon="mdi-calendar"
                readonly
                color="black"
                v-bind="attrs"
                v-on="on"
                :class="
                  $vuetify.breakpoint.smAndDown ? '' : 'date-text-field ml-6'
                "
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              scrollable
              range
              color="black"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="black" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" sm="6">
          <v-btn> <v-icon>mdi-cogs</v-icon> Settings</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dates: ["2022-05-15", "2022-09-15"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,

      // selected Category
      selectedItem: 1,

      categories: [
        { name: "Bulk Load(EBM/Etax)", icon: "mdi-file-multiple-outline" },
        { name: "EBM Data Analysis", icon: "mdi-network" },
        { name: "Imports Analysis", icon: "mdi-airplane-landing" },
        { name: "Inventory Analysis", icon: "mdi-archive-check-outline" },
        { name: "Cost Analysis", icon: "mdi-cash-multiple" },
        { name: "Etax & EBM Data Comparison", icon: "mdi-arrow-decision" },
      ],
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  methods: {
    sortByCategory(category) {
      console.log(category);
    },
  },
};
</script>

<style>
.date-text-field {
  max-width: 210px !important;
}

.v-progress-circular {
  margin: 1rem;
}
/* Filter container */
.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1.9rem; /* Adjust the padding value as needed */
}
.card-decorator {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.5rem;
}
</style>
