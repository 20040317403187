<template>
  <v-row>
    <v-col cols="12" md="12" sm="12" justify="center" align="center">
      <v-card max-width="480" elevation="4">
        <v-card-text>
          <div>
            <v-avatar
              :size="$vuetify.breakpoint.mdAndDown ? '70' : '90'"
              color="blue"
            >
              <v-icon x-large color="white">mdi-email-check</v-icon>
            </v-avatar>
            <v-divider class="divider"></v-divider>
          </div>
          <h3>E-mail verification has been sent!</h3>
          <h6 class="ma-4">
            Dear <b>{{ email }}</b
            >,<br />

            Find an email verification sent at
            <em class="blue--text verification-email">{{ email }}</em> and click
            on the provided link. It'll be our pleasure to have you here.
          </h6>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn depressed text color="primary">Why verify?</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed text color="orange" @click="goBack()"
            >Take me back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      email: "",
    };
  },
  created() {
    this.username = localStorage.getItem("newComerUsername");
    this.email = localStorage.getItem("newComerEmail");
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/welcome" });
    },
  },
};
</script>

<style scoped>
.divider {
  width: 300px !important;
  color: #3b81f7 !important;
}
.verification-email {
  cursor: pointer;
}

.verification-email:hover {
  text-decoration: underline;
}
</style>
